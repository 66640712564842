.wrapper {
	position: relative;
	display: inline-block;
}

.input {
	appearance: none;
	position: absolute;
	width: 100%;
	height: 100%;
	outline: none;
	cursor: pointer;
}

.input:checked::after {
	content: '✖';
	font-size: 0.75rem;
	color: white;
	background-color: red;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 1rem;
	width: 1rem;
	position: absolute;
	right: 0;
	transform: translate(25%, -25%);
}

.label {
	display: block;
	cursor: pointer;
}

.label > img {
	display: block;
}