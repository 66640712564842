.colors {
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.color {
	margin: 0.25rem;
	position: relative;
	cursor: pointer;
}

.color > span {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	text-align: center;
	transform: translateY(50%);
	font-weight: 600;
}

.color > img {
	display: block;
}

.image {
	max-width: 100%;
}

.view {
	position: relative;
}

.view > img {
	display: block;
	margin: auto;
}

.arrow {
	width: 3rem;
	height: 3rem;
	position: absolute;
	background-color: transparent;
	top: 50%;
	border: none;
	border-top: 4px solid var(--color-lighter);
	border-right: 4px solid var(--color-lighter);
	cursor: pointer;
	outline: none;
}

.right {
	right: 0;
	transform: translate(-50%, -50%) rotate(45deg);
}

.left {
	left: 0;
	transform: translate(50%, -50%) rotate(-135deg);
}

.thumbs {
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	justify-content: center;
}

.thumbs > li {
	margin: 0 .25rem;
	display: flex;
	justify-content: center;
}

.thumbs > li > img {
	display: block;
}