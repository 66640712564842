.wrapper {
	padding: 1rem;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.list {

}

.list > *:not(:last-child) {
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
}

.header {
	font-weight: 600;
}

.tag {
	appearance: none;
	border: none;
	padding: 0.25rem 0.5rem;
	background-color: var(--color-lighter);
	transition: filter var(--anim-duration);
	cursor: pointer;
}

.tag:hover {
	filter: brightness(95%);
}

.tag > span {
	margin-right: 0.5rem;
}

.clear {
	appearance: none;
	border: none;
	background-color: transparent;
	padding: 0;
	margin-left: auto;
	cursor: pointer;
	white-space: nowrap;
	outline: none;
}

.clear:hover {
	text-decoration: underline;
}

.clear > span {
	margin-right: 0.5rem;
}