.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 164px;
	min-height: 254px;
}

@media only screen and (max-width: 1280px) {
	.wrapper {
		width: calc(50vw - 2rem);
	}
}

.code {
	font-weight: 800;
	margin-right: 0.25rem;
	text-decoration: none;
}

.name {
	font-weight: 600;
	text-decoration: none;
}

.title {
	color: var(--color-light);
	margin: 0.5rem 0;
	font-size: 1rem;
	text-transform: uppercase;
}

.subtitle {
	margin: 0.5rem 0;
	text-align: center;
}

.colors {
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	max-width: 100%;
	justify-content: center;
}

.colors > li {
	display: block;
	margin: 1px;
}

.colors > li > img {
	display: block;
	width: 1.5rem;
}