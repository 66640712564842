.wrapper {
	max-width: 100%;
	overflow: hidden;
	position: relative;
}

.film {
	display: flex;
	width: 100%;
	max-height: 720px;
	transition: transform var(--anim-duration);
}

.film > * {
	width: 100%;
	display: block;
	flex-shrink: 0;
	object-fit: contain;
}

.arrow {
	width: 3rem;
	height: 3rem;
	position: absolute;
	background-color: transparent;
	top: 50%;
	border: none;
	border-top: 4px solid white;
	border-right: 4px solid white;
	cursor: pointer;
	outline: none;
}

.right {
	right: 0;
	transform: translate(-50%, -50%) rotate(45deg);
}

.left {
	left: 0;
	transform: translate(50%, -50%) rotate(-135deg);
}

.dots {
	position: absolute;
	margin: 1rem;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.dot {
	border: none;
	padding: 0;
	width: 1rem;
	height: 1rem;
	background-color: white;
	display: block;
	border-radius: 50%;
	cursor: pointer;
	outline: none;
	border: 2px solid #00000016;
}

.dot:not(:last-of-type) {
	margin-right: 1rem;
}

.active {
	border: 3px solid gray;
}