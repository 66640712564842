.top {
	padding: 1rem var(--padding);
	display: flex;
	border-bottom: 1px solid #e7e7e7;
	justify-content: space-between;
}

.settings {
	display: flex;
	align-items: center;
}

.main {
	display: flex;
	justify-content: space-between;
	padding: 1rem var(--padding);
	border-bottom: 1px solid #e7e7e7;
	align-items: center;
}

.logo {
	height: 60px;
	max-width: 250px;
	display: block;
}

.navigation {
	display: flex;
	align-items: center;
	padding: 0.5rem;
}

.navigation > a {
	text-decoration: none;
}


.navigation > a:hover {
	color: var(--color-primary);
	text-decoration: underline;
}

.active {
	color: var(--color-primary);
}

.navigation > a:not(:last-of-type) {
	padding-right: 1rem;
}

.content {
	padding: 0 var(--padding);
	flex-grow: 1;
}

.content__full_width {
	padding: unset;
}

.footer {
	background-color: #333333;
	color: #c2c1c1;
	padding: 1rem var(--padding);
}

.footer_sections {
	display: flex;
	word-break: break-word;
}

.footer_sections > * {
	flex: 1 0 0;
}

.copyright {
	text-align: center;
}

.socials {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	justify-content: center;
}

.socials_bottom {
	width: 100%;
}

.socials > *:not(:last-of-type) {
	margin-right: 1rem;
}

.socials > * > a {
	color: #c2c1c1;
	display: block;
	transition: transform var(--anim-duration);
}

.socials > * > a:hover {
	transform: scale(1.2);
}