.wrapper {
	color: white;
	background-color: transparent;
	border: none;
	display: inline-flex;
	cursor: pointer;
	padding: 0;
	outline: none;
	color: var(--color-light);
	border-radius: 50%;
	transition: background-color var(--anim-duration);
}

.type_primary {
	color: var(--color-primary);
}

.wrapper:disabled {
	cursor: not-allowed;
	color: lightgray;
}

.wrapper:hover {
	background-color: rgba(0, 0, 0, 0.08);
}

.wrapper > svg {
	width: 1.5rem;
	fill: currentColor;
	margin: 0.5rem;
}

.type_dangerous {
	color: tomato;
}
.type_dangerous:hover {
	background-color: rgba(255, 0, 0, 0.08);
}