[tooltip] {
	--background: rgba(0, 0, 0, 0.85);
	--delay: 0.15s;
	--z-index: 100;

	position: relative;
}

[tooltip]::after {
	content: attr(tooltip);
	position: absolute;
	visibility: hidden;
	z-index: var(--z-index);
	background-color: var(--background);
	color: white;
	font-size: 0.9rem;
	font-weight: 400;
	padding: 0.4rem 0.5rem;
	border-radius: 3px;
	left: 50%;
	top: 0;
	transform: translate(-50%, calc(-100% - 12px));
	white-space: nowrap;
	text-transform: none;
	line-height: 1.3rem;
}

[tooltip]:hover::after {
	visibility: visible;
}

[tooltip]:hover::before {
	visibility: visible;
}

[tooltip-placement='left']::before {
	left: 0;
	top: 50%;
	transform: translate(-100%, -50%) rotate(-90deg);
}

[tooltip-placement='left']::after {
	left: 0;
	top: 50%;
	margin-left: -11px;
	transform: translate(-100%, -50%);
}

[tooltip-placement='right']::before {
	left: 100%;
	top: 50%;
	transform: translate(0, -50%) rotate(90deg);
}

[tooltip-placement='right']::after {
	left: 100%;
	top: 50%;
	transform: translate(0, -50%);
	margin-left: 12px;
}

[tooltip-placement='bottom']::before {
	left: 50%;
	top: 100%;
	transform: translate(-50%, 0) rotate(-180deg);
}

[tooltip-placement='bottom']::after {
	top: 100%;
	transform: translate(-50%, 12px);
}

[tooltip-multiline="true"]::after {
	white-space: normal;
	width: 320px;
	text-align: justify;
}