.wrapper {
	position: relative;
}

.search {
	display: flex;
	align-items: center;
	gap: 1rem;
	border: 1px solid var(--color-lighter);
	padding: 0.5rem 1rem;
}

.input {
	border: none;
	outline: none;
}

.results {
	background-color: white;
	box-shadow: var(--shadow);
	position: absolute;
	left: 0;
	right: 0;
	max-height: 16rem;
	overflow: auto;
	padding: 0.5rem 0;
	opacity: 0;
	transition: opacity 300ms;
	z-index: 1;
}

.results_open {
	opacity: 1;
}

.row {
	padding: 0.5rem 1rem;
	display: block;
	cursor: pointer;
	transition: all 300ms;
}

.row:hover {
	background-color: var(--color-lighter);
}

.row_code {
	display: inline-block;
	min-width: 3ch;
	text-align: right;
}