.table {
	width: 100%;
	border-collapse: collapse;
	text-align: center;
}

.table th {
	font-weight: 600;
}

.table td, .table th {
	border: 1px solid #e0e0e0;
	padding: 0.5rem 1rem;
}

.color {
	display: flex;
	align-items: center;
	justify-content: center;
}

.color > span {
	margin-left: 0.5rem;
}

.currency {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.table .add_cell {
	padding: 0 0.25rem;
	width: 20px;
}

.input {
	padding: 0.5rem;
	border: 1px solid var(--color-light);
	appearance: none;
	width: 10ch;
	text-align: center;
}

/* Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input {
  -moz-appearance: textfield;
}

.table_wrapper {
	overflow: auto;
}