.wrapper {
	background-color: var(--color-lighter);
	padding: 1rem;
}

.filter_group:not(:last-of-type) {
	margin-bottom: 1.5rem;
}

.filter_group > h3 {
	font-weight: 600;
	margin: 1rem 0;
	font-size: 1rem;
}

.filter_group > ul {
	margin: 0;
	padding: 0;
	list-style: none;
	--gap: 0.25rem;
	display: inline-flex;
	flex-wrap: wrap;
	margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
	width: calc(100% + var(--gap));
}

.filter_group > ul > * {
  margin: var(--gap) 0 0 var(--gap);
}

.filter_group > ul > li > span {
  display: block;
}

.filter_group__icon img {
	width: 4rem;
}

.filter_group__checkbox > ul {
	flex-direction: column;
}

.filter_group__color img {
	width: 1.57rem;
}

.show {
	padding-top: 0.5rem;
	text-decoration: underline;
	cursor: pointer;
}