.wrapper {
	display: grid;
	grid-template-areas: 
		"thumb title code"
		"thumb desc desc"
		"bar bar bar"
	;
	grid-template-columns: min-content 1fr min-content;
	grid-template-rows: min-content auto 1fr;
	justify-content: start;
	gap: 0.5rem;
	border: 1px solid var(--color-lighter);
	padding-top: 1rem;
}

.wrapper:not(:last-of-type) {
	margin-bottom: 1rem;
}

.title {
	grid-area: title;
	font-weight: 600;
	cursor: pointer;
}

.thumb {
	grid-area: thumb;
	justify-self: end;
	margin-left: 0.5rem;
	margin-right: 0.25rem;
}

.code {
	grid-area: code;
	text-align: right;
	padding-right: 0.5rem;
}

.desc {
	grid-area: desc;
	line-height: 1.4rem;
}

.bar {
	grid-area: bar;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: var(--color-lighter);
}

.quantity {
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.quantity_input {
	padding: 0.25rem 0.5rem;
	border: 1px solid var(--color-light);
	appearance: none;
	width: 7ch;
	text-align: center;
}

/* Chrome, Safari, Edge, Opera */
.quantity_input::-webkit-outer-spin-button,
.quantity_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.quantity_input {
  -moz-appearance: textfield;
}

.price {
	padding: 0.5rem;
}

@media screen and (min-width: 992px) {
	.wrapper {
		grid-template-areas: 
			"thumb title code bar"
			"thumb desc desc bar"
		;
		grid-template-columns: min-content 1fr min-content 24rem;
		grid-template-rows: auto auto;
		padding-top: 0rem;
	}
	.wrapper__without_price {
		grid-template-columns: min-content 1fr min-content min-content;
	}
	.thumb {
		padding: 0.5rem 0;
	}
	.title {
		padding-top: 0.5rem;
	}
	.code {
		padding-top: 0.5rem;
	}
	.bar {
		padding: 0 0.5rem;
	}
}