@import "./styles/normalize.css";
@import "./styles/tooltip.css";
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,800&display=swap&subset=cyrillic,cyrillic-ext,latin-ext,vietnamese');


* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #363636;
}

@media screen and (min-width: 992px) {
  .fx.responsive {
    flex-direction: column;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --color-primary: #67BD3C;
  --color-light: #878c8c;
  --color-lighter: #e2e2e2;

  --max-width: 1280px;
  --shadow: 0 2px 5px rgba(0,0,0,0.15);
  /* --padding: max(calc((100vw - var(--max-width)) / 2), 1rem); */
  --anim-duration: 300ms;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

svg {
  width: 1.5rem;
}

hr {
  border: none;
  border-bottom: 1px solid var(--color-lighter);
  margin: 1rem 0;
}

a {
  color: inherit;
  text-decoration: none;
}

.full-width {
  position: relative;
  width: 100vw;
  left: calc(-1 * var(--padding));
}

center {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

strong {
  font-weight: 600;
}