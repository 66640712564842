.modal {
	width: 480px;
}

.field:not(:last-of-type) {
	margin-bottom: 2rem;
}

.field > label {
	margin-bottom: 1rem;
	display: block;
}

.field > input {
	display: block;
	width: 100%;
	border: 1px solid var(--color-light);
	padding: 0.5rem 0.75rem;
	border-radius: 2px;
}