.wrapper {
	position: relative;
	display: inline-block;
}

.value {
	position: absolute;
	right: -0.25rem;
	top: -0.25rem;
	background-color: lightgray;
	font-weight: 600;
	border-radius: 50%;
	padding: 0 0.25rem;
}