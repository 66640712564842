.warning {
	color: orange;
	font-weight: 600;
	text-align: justify;
	line-height: 1.5rem;
	white-space: pre-line;
}

.price_both {
	color: var(--color-light);
	font-weight: 400;
}