.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	background-color: rgba(0, 0, 0, 0.32);
	opacity: 0;
	pointer-events: none;
	transition: opacity var(--anim-duration);
}

.backdrop__open {
	pointer-events: all;
	opacity: 1;
}

.filter_button {
	padding: 1rem;
	width: 100%;
}

.filter_close {
	position: absolute;
	top: 1rem;
	right: 0.5rem;
	color: black;
}

@media only screen and (min-width: 992px) {
	.backdrop, .filter_button, .filter_close {
		display: none;
	}
	.catalog {
		display: flex;
	}
}

.filter {
	width: 320px;
	flex-shrink: 0;
}

@media only screen and (max-width: 1280px) {

	.filter {
		position: fixed;
		top: 0;
		height: calc(100 * var(--vh, 1vh));
		overflow: auto;
		z-index: 2;
		transform: translateX(-100%);
		transition: transform var(--anim-duration);
	}

	.filter__open {
		transform: translateX(0);
	}

	.products {
		padding: 1rem;
	}

}

.products {
	flex-grow: 1;
	padding: 1rem 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.product {
	margin: 0.5rem;
}

.header {
	text-align: center;
}