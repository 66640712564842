.wrapper {
	cursor: pointer;
	position: relative;
	display: flex;
	align-items: center;
}

.wrapper:hover > .dropdown {
	visibility: visible;
}

.arrow {
	color: gray;
}

.dropdown {
	visibility: hidden;
	background-color: white;
	box-shadow: var(--shadow);
	position: absolute;
	bottom: 0;
	transform: translateY(100%);
}