@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
@keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

.wrapper {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.16);
	z-index: 1;
	transition: all var(--anim-duration);
	animation: fadeIn ease var(--anim-duration);
}

.close {
	visibility: hidden;
	animation: fadeOut ease var(--anim-duration);
}

.body {
	background-color: white;
	box-shadow: var(--shadow);
	min-width: 320px;
	max-width: calc(100vw - 2rem);
	max-height: calc(calc(100 * var(--vh, 1vh)) - 2rem);
	display: flex;
	flex-direction: column;
}

.header {
	padding: 1rem;
	font-weight: 600;
	border-bottom: 1px solid var(--color-lighter);
	position: relative;
}

.header_close {
	position: absolute;
	top: 50%;
	right: 1rem;
	transform: translateY(-50%);
	color: var(--color-light);
}

.content {
	padding: 1rem;
	flex-shrink: 1;
	overflow: auto;
}

.footer {
	padding: 1rem;
	border-top: 1px solid var(--color-lighter);
}