.image {
	cursor: zoom-in;
	max-width: 100%;
}

.thumbs {
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	justify-content: space-between;
	cursor: zoom-in;
}

.thumbs > li {
	flex: 1 0 0;
	display: flex;
	justify-content: center;
}

.thumbs > li > img {
	display: block;
}

.show_more {
	border: 2px solid var(--color-lighter);
	background: transparent;
	height: 100%;
	width: 83%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
}

.show_more > svg {
	color: var(--color-lighter);
	width: 2rem;
}

.show_more > p {
	margin: 0;
	font-size: 0.9rem;
	font-weight: 600;
}