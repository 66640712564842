.button {
	border: none;
	background-color: transparent;
	padding: 0.5rem 1rem;
	font-size: 1rem;
	background-color: var(--color-lighter);
	outline: none;
	font-family: montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	cursor: pointer;
	transition: filter var(--anim-duration);
	display: inline-flex;
	align-items: center;
	gap: 0.5rem;
}

.button:hover {
	filter: brightness(95%);
}

.type_primary {
	font-weight: 600;
	background-color: var(--color-primary);
	color: white;
}

.type_white {
	background-color: white;
	color: black;
	box-shadow: 0 2px 5px #00000026;
}

.button:disabled {
	opacity: 0.32;
}