.wrapper {
	display: flex;
	align-items: center;
	flex-direction: column;
}

@media only screen and (min-width: 992px) {
	.wrapper {
		justify-content: space-between;
		flex-direction: row;
	}
	.aside {
		width: calc(100% / 1.618);
	}
}

.header {
	text-transform: uppercase;
	font-weight: 800;
}

.header > span {
	margin-left: 0.5rem;
	color: var(--color-light);
}

.subheader {
	font-size: 1rem;
	font-weight: 600;
}

.specification {
	font-style: italic;
	margin-top: 2rem;
}

.specification ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.description {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.description ul {
	padding-left: 1.5rem;
}

.description li {
	margin: 0.5rem 0;
}

.icons {
	list-style: none;
	margin: 0;
	padding: 1rem;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 2rem;
	align-self: flex-start;
}

.attribute {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.attribute > img {
	width: 4rem;
}

.attribute > span {
	text-align: center;
}

.colors {
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
}

.color {
	margin: 0.25rem;
	position: relative;
}

.color > span {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	text-align: center;
	transform: translateY(50%);
	font-weight: 600;
}

.color > img {
	display: block;
}